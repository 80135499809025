import React, { useEffect, useState } from "react";
import "./TradeOrderInput.css";

import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const TradeOrderInput = ({tradeOption, userTokenAmount, isReadOnly, tokenChoice, tokenPrice, userBalance, amount, tradeAmountCrypto, isUSD, setIsUSD, handleAmountChange, isInputValid, setIsInputValid}) => {
    
    const [isInputActive, setIsInputActive] = useState(false);
    // const [userCryptoHoldings, setUserCryptoHolding] = useState(null);
    
    const inputClassName = isInputActive ? "trade-order-wrapper-active" : "trade-order-wrapper-inactive";

    const handleInputSelect = () => {
        setIsInputActive(!isInputActive);
    };

    const validateAmountChange = (event) => {

        const inputValue = event.target.value;

        const newRegEx = /^\d*\.?\d*$/;

        if (newRegEx.test(inputValue)) {
            setIsInputValid(true);
            if (isUSD) {
                handleAmountChange(inputValue, "USD");
                if (tradeOption === "buy" && inputValue > userBalance) {
                    setIsInputValid(false);
                } else if (tradeOption === "sell" && inputValue > tokenPrice * userTokenAmount) {
                    setIsInputValid(false);
                }
            } else {
                handleAmountChange(inputValue, "CRYPTO");
                if (tradeOption === "buy" && (inputValue * tokenPrice) > userBalance) {
                    setIsInputValid(false);
                } else if (tradeOption === "sell" && inputValue > userTokenAmount ) {
                    setIsInputValid(false);
                }
            }
        }
    }

    const quickSelectClicked = (percentage) => {
        if (tradeOption === "buy") {
            if (isUSD) {
                const amount = userBalance * (percentage/100);
                handleAmountChange(amount, "USD");
            } else {
                const amount = (userBalance / tokenPrice) * (percentage/100);
                handleAmountChange(amount, "CRYPTO")
            }
        } else if (tradeOption === "sell" && userTokenAmount > 0) {
            if (isUSD) {
                const amount = userTokenAmount * tokenPrice * (percentage/100);
                handleAmountChange(amount, "USD");
            } else {
                const amount = userTokenAmount * (percentage/100);
                handleAmountChange(amount, "CRYPTO");
            }
        }

        // setQuickSelectConversion(userTokenHolding[tokenChoice]["amount"] * percentage/100)
    }

    // useEffect(() => {
    //     setUserCryptoHolding(userTokenAmount);
    // },[tokenChoice])

    return(
        <div className="trade-information-container">
            <div className={isInputValid ? inputClassName : "trade-order-wrapper-invalid" }>
                <p className="trade-order-input-amount">AMOUNT</p>

                <input className="trade-order-input"
                    type={"text"}
                    value={amount}
                    onChange={validateAmountChange}
                    onFocus={handleInputSelect}
                    onBlur={handleInputSelect}
                    readOnly={isReadOnly}
                />

                <div className="trade-order-currency" onClick={() => {
                    setIsUSD(!isUSD);
                    handleAmountChange(0);
                }}>
                    <p className="trade-order-currency-swap">{isUSD ? "USD" : tokenChoice}</p>
                    <SwapHorizIcon fontSize="small"/>
                </div>
            </div>

            <div className="trade-order-quick-options">
                <p onClick={() => quickSelectClicked(25)}>25%</p>
                <p onClick={() => quickSelectClicked(50)}>50%</p>
                <p onClick={() => quickSelectClicked(75)}>75%</p>
                <p onClick={() => quickSelectClicked(100)}>100%</p>
            </div>
                
            {(isUSD) &&
                <div className="trade-order-token-conversion">
                    <p>≈ {tradeAmountCrypto} {tokenChoice}</p>
                </div>
            }
            {isReadOnly && 
                <p className="text-tiny trade-order-read-only-text">currently only supports selling entire holding amount</p>
            }
        </div>
    );
};

export default TradeOrderInput;