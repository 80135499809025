import "./Dashboard.css";
import React, { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";

import { getClosedTradesByUserId, getCoinBaseData, getOpenPositionsByUserId, getUserBalance } from "../../helpers/api";
import { formatDataForGrid } from "../../helpers/formatOpenPostions";
import { removeUnsupportedTokens } from "../../helpers/removeUnsupportedTokens";

import OpenPositions from "../../components/postions/OpenPositions";
import PortfolioMetrics from "../../components/portfolioMetrics/PortfolioMetrics";
import Spinner from "../../components/loadingAnimations/Spinner";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { OpenPositionsRow, OpenPositionsTable } from "../../components/Table/OpenPositionsTable";
import TradeInstructions from "../../components/TradeInstructions/TradeInstructions";
import Activity from "../../components/Activity/Activity";

import Cookies from "js-cookie";
import { WalkthroughModal } from "../../components/WalkthroughModal/WalkthroughModal";

const Dashboard = () => {

  const { user } = useAuth();
  const { isMobile } = useTheme();

  const [openPositionData, setOpenPositionData] = useState(null);
  const [userBalance, setUserBalance] = useState(null);

  const [showFirstTimeLoginModal, setShowFirstTimeLoginModal] = useState(false);

  const [closedTrades, setClosedTrades] = useState(null);

  const date = new Date();

  // Close modal and set reshowing of modal to false or true based on modal checkbox
  const handleWalkthroughModalClose = (isReshowModal) => {
    Cookies.set("showFirstTimeLogin", isReshowModal);
    setShowFirstTimeLoginModal(false);

    // TODO: Add session storage logic to keep from showing again on dashboard until new session is started.

  } 

  const fetchDataForTables = async () => {
    // this will call both API calls at once and await a return of the promise befor proceeding into the .then
    return Promise.all([
      getOpenPositionsByUserId(user.userId),
      getCoinBaseData(),
      getUserBalance(user.userId),
    ]).then(([openTransactionsData, coinbaseData, userBalance]) => {
      // Deconstructing the reutrn array from .all into the data that is returned from the functions.
      const supportedOpenTransacitonData = removeUnsupportedTokens(openTransactionsData, coinbaseData);
      setOpenPositionData(formatDataForGrid(supportedOpenTransacitonData, coinbaseData));
      setUserBalance(userBalance);
    });
  };

  const fetchClosedTrades = async () => {
    return Promise.all([
      getClosedTradesByUserId(user.userId),
    ]).then(([closedTradeData]) => {
      // Endpoint returns null for closed Trades if DB has not be populated yet.
      closedTradeData && setClosedTrades(closedTradeData);
    });
  };

  const refreshDashboardData = () => {
    fetchDataForTables().then();
    fetchClosedTrades().then();
  }

  useEffect(() => {
    const cookieName = "showFirstTimeLogin";
    const showFirstTimeLogin = Cookies.get(cookieName);

    if (showFirstTimeLogin === null || showFirstTimeLogin === "true" || showFirstTimeLogin === undefined) {
      const sessionShowModal = sessionStorage.getItem("sessionShowModal");
      if (sessionShowModal === null || sessionShowModal === undefined || sessionShowModal === "true"){
        setShowFirstTimeLoginModal(true);
        sessionStorage.setItem("sessionShowModal", "false");
      }

    }

  }, [])

  useEffect(() => {
    if (user) {
      // console.log("calling use effect")
      fetchDataForTables().then();
      fetchClosedTrades().then();
    }
    // eslint-disable-next-line
  }, [user]);

  if (isMobile) {
    return(
      <DashboardLayout page={"Dashboard"}>
        <div className="dashboard-content">
          <PortfolioMetrics openPositionData={openPositionData} userBalance={userBalance} />
          <h1 className="mobile-op-title">Open Positions</h1>
          <div className="mobile-table-updated">
            <p>last updated {date.toString()}</p>
          </div>
          {openPositionData && openPositionData.length > 0 ? (
            <OpenPositionsTable>
              {openPositionData.map((item, index) => (
                <OpenPositionsRow 
                  key={index} 
                  token={item.id.split('USD')[0]}
                  amount={item.amount}
                  costBasis={item.avgPrice * item.amount}
                  currentPrice={item.currentPrice}
                  currentValue={item.amount * item.currentPrice}
                  profit={item.profit}
                  avgPrice={item.avgPrice}
                />
              ))}
            </OpenPositionsTable>
          ) : openPositionData && openPositionData.length === 0 ? (
            <TradeInstructions />
          ) : (
            <Spinner containerClass={"spinner-table-container"} />
          )}
          {closedTrades &&
            <Activity closedTrades={closedTrades}/>
          }
        </div>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout page={"Dashboard"}>
        <div className="dashboard-content">
          {/* <Header /> */}
          <PortfolioMetrics openPositionData={openPositionData} userBalance={userBalance} />
          {openPositionData ? (
            <OpenPositions openPositionData={openPositionData} refreshDashboardData={refreshDashboardData} />
          ) : (
            <Spinner containerClass={"spinner-table-container"} />
          )}
          {closedTrades &&
            <Activity closedTrades={closedTrades}/>
          }
        </div>
      </DashboardLayout>
      {showFirstTimeLoginModal && <WalkthroughModal handleWalkthroughModalClose={handleWalkthroughModalClose} />}
    </>
  );
};

export default Dashboard;
