import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import "./OpenPositions.css";
import { columns } from "./columns";

import TradeInstructions from "../TradeInstructions/TradeInstructions";
import TradingViewWidgetModal from "../TradingViewWidget/TradingViewWidgetModal";

const OpenPositions = ({ openPositionData, refreshDashboardData }) => {
  // Build MUI Data Grid
  const tableColumns = columns;

  const date = new Date();

  const [tokenId, setTokenId] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleCellClick = (params, event, details) => {
    // console.log(params);
    if (params.field === "id") {
      // console.log(params.id)
      setShowModal(true);
      setTokenId(params.id)
    }
  }

  return (
    <>
      <div className="open-positions">
        <div className="open-positions-header">
          <h1 className="open-positions-title text-header">Open Positions</h1>
          <p className="open-positions-updated-time text-xsmall">
            last update {date.toString()}
          </p>
        </div>
          {openPositionData.length === 0 ? (
            <TradeInstructions />
          ) : (
            <div className="table">
              <DataGrid
                rows={openPositionData}
                columns={tableColumns}
                slots={{ toolbar: GridToolbar }}
                onCellClick={handleCellClick}
              />
            </div>
          )}
      </div>
      {showModal && tokenId && <TradingViewWidgetModal tokenId={tokenId} setShowModal={setShowModal} openPositionData={openPositionData} refreshDashboardData={refreshDashboardData} /> } 
    </>
  );
};

export default OpenPositions;
