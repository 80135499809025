import React, {useRef, useState} from "react";

import "./BragCardModal.css";

import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import IosShareIcon from '@mui/icons-material/IosShare';

import BragCardLogo from "./BragCardLogo";
import Spinner from "../loadingAnimations/Spinner";


const BragCardModal = ({trade, setShowModal}) => {

    const [showSpinner, setShowSpinner] = useState(false);

    // Deconstruct Trade Object
    const {
        amount: { N: amount },
        avgPrice: { N: avgPrice },
        closeDate: { S: closeDate },
        closePrice: { N: closePrice },
        openDate: { S: openDate },
        tokenPair: { S: tokenPair },
        txnId: { S: txnId },
        txnSource: { S: txnSource },
        userId: { S: userId }
    } = trade;

    const profit = ( ( closePrice - avgPrice ) / avgPrice ) * 100;
    const dateTokens = trade.closeDate.S.split("T");

    const referralCode = "CO123af";

    // Configuring Share Functionality for Brag Card using html-to-image and Web share API
    const imageRef = useRef(null);

    // const handleShare = async() => {
    //     setShowSpinner(true);
    //     try {
    //         // Convert div to image
    //         const dataUrl = await toPng(imageRef.current);

    //         // Create a canvas element
    //         const canvas = document.createElement('canvas');
    //         const ctx = canvas.getContext('2d');

    //         // Set canvas dimensions
    //         canvas.width = imageRef.current.offsetWidth;
    //         canvas.height = imageRef.current.offsetHeight;

    //         // Create an image element
    //         const img = new Image();

    //         // When image loads, draw it onto the canvas
    //         img.onload = () => {
    //             ctx.drawImage(img, 0, 0);
                
    //             // Copy canvas content to clipboard
    //             canvas.toBlob((blob) => {
    //                 navigator.clipboard.write([
    //                     new ClipboardItem({ 'image/png': blob })
    //                 ]).then(() => {
    //                     console.log('Copied to clipboard!');
    //                     setShowSpinner(false);
    //                     alert('Image copied to clipboard!');
    //                 }, (error) => {
    //                     console.error('Unable to write to clipboard. Error:', error);
    //                     alert('Something went wrong! Please contact support at support@chart.observer.')
    //                     setShowSpinner(false);
    //                 });
    //             });
    //         };

    //         // Set image source to data URL
    //         img.src = dataUrl;
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    const handleShare = async () => {
        setShowSpinner(true);
        try {
          const dataUrl = await toPng(imageRef.current);
    
          const blob = await (await fetch(dataUrl)).blob();
    
          await navigator.clipboard.write([
            new ClipboardItem({ 'image/png': blob })
          ]);
    
        //   console.log('Copied to clipboard!');
          alert('Image copied to clipboard!');
        } catch (error) {
          console.error('Error:', error);
          alert('Something went wrong! Please contact support at support@chart.observer.');
        } finally {
          setShowSpinner(false);
        }
    };

    return(
        <div className="bc-modal-overlay" onClick={() => setShowModal(false)}>
            <div className="bc-modal-container" ref={imageRef} onClick={(e) => e.stopPropagation()}>
                <div className="bc-modal">
                    <div className="bc-logo-wrapper">
                        <BragCardLogo/>
                        {showSpinner ? (
                            <Spinner spinnerSize="spinner-small"/>
                        ) : (
                            <IosShareIcon className="bc-modal-share-icon" onClick={handleShare}/>
                        )}
                    </div>
                    <div className="bc-header">
                        <h2 className="text-header bc-modal-token">{tokenPair}</h2>
                        <p className="text-xsmall bc-method">{txnSource}</p>
                    </div>
                    <h1 className={`text-hero-large bc-profit-${profit > 0 ? 'green' : 'red'}`}>{profit.toFixed(2)}%</h1>
                    <div className="bc-trade-detail-container">
                        <div className="bc-entry-wrapper">
                            <p className="text-base bc-modal-trade-detail-title">Entry Price</p>
                            <p className="text-base bc-trade-detail-subtext">
                                {avgPrice < 1 ? `$${(parseFloat(avgPrice)).toFixed(6)}` : parseFloat(avgPrice).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                                
                            </p>
                        </div>
                        <div className="bc-exit-wrapper">
                            <p className="text-base bc-modal-trade-detail-title">Exit Price</p>
                            <p className="text-base bc-trade-detail-subtext">
                                {parseFloat(closePrice) < 1 ? `$${parseFloat(closePrice).toFixed(6)}` : parseFloat(closePrice).toLocaleString("en-US", {style: "currency", currency: "USD"})}
                            </p>
                        </div>
                    </div>
                    <div className="bc-modal-date-wrapper">
                        <p className="text-base bc-trade-detail-title">&nbsp;</p>
                        <p className="text-sm bc-trade-detail-subtext">
                            Closed: {dateTokens[0]} {dateTokens[1]}
                        </p>
                    </div>

                </div>
                <div className="bc-modal-referral">
                    <div className="bc-modal-referral-text">
                        <p className="text-sm">Test your strategy without the financial risk</p>
                        <p className="text-base weight-semi-bold">https://chart.observer</p>
                    </div>
                    <div className="bc-modal-qr">
                        <QRCode
                            size={256}
                            style={{ height: "60px", maxWidth: "60px", width: "60px" }}
                            value={`https://chart.observer/`}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BragCardModal;