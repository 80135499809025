import React, { useEffect, useState } from "react";

import "./Activity.css";

import BragCard from "./BragCard";

const Activity = ({closedTrades}) => {

    const [bragCards, setBragCards] = useState();

    useEffect(() => {
        setBragCards(closedTrades);
    },[]);

    if (closedTrades.length > 0) {
        return(
            <div className="activity-container">
                <div className="activity-header">
                    <h1 className="activity-title text-header">Activity</h1>
                </div>
                <div className="activity-wrapper">
                    {bragCards && bragCards.length > 0 ? (
                        bragCards.map((closedtrade, index) => (
                            <BragCard key={index} trade={closedtrade}/>
                        ))
                    ) : (
                        <h1>No Activity</h1>
                    )}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Activity;